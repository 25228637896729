import { ConnectWalletButton } from "./styled";
import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useAccount, useSignMessage, useSwitchChain, useDisconnect } from "wagmi";
import { useAppKit, useAppKitEvents } from '@reown/appkit/react'
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "src/components/icons";

export const Button = ({ text, redirect_url, requiredChain }) => {
  const { signMessageAsync } = useSignMessage();
  const { address, isConnected, chainId } = useAccount();
  const { disconnect } = useDisconnect();
  const { switchChain } = useSwitchChain();
  const [status, setStatus] = useState("idle");
  const [loading, setLoading] = useState(false);

  const getNonce = async address => {
    const nonce = await axios.post("auth/login_nonces", { address }).then(res => res.data);
    return nonce.nonce;
  };

  const login = useMutation({
    mutationFn: async signature => {
      const res = await axios.post("auth/wallet_login", {
        address: address?.toLowerCase(),
        signature,
        chain_id: requiredChain.id
      });
      return res;
    }
  });

  const { open } = useAppKit();
  const { data } = useAppKitEvents();

  const handleConnectWallet = async () => {
    if (!address) {
      open();
      toast.error("Please connect your wallet first", {
        autoClose: 3000
      });
      return;
    }

    if (chainId != requiredChain.id) {
      toast.info("You must first switch to the Base network", {
        autoClose: 3000
      });
      switchChain({ chainId: requiredChain.id });
    }

    try {
      let nonce = await getNonce(address);
      while (!nonce) {
        await new Promise(resolve => setTimeout(resolve, 500));
        nonce = await getNonce(address);
        if (!address) {
          toast.error("Please connect your wallet first", {
            autoClose: 3000
          });
          return;
        }
      }
      const message = `Sign in with Talent Protocol\nnonce: ${nonce}`;
      const signature = await signMessageAsync({ message });
      const response = await login.mutateAsync(signature);
      disconnect();
      window.location.href = `${redirect_url}?auth_token=${response.data.auth_token}`;
      return false;
    } catch (error) {
      toast.error(`An error occured while connecting wallet: ${error.response?.data?.error || error.message}`, {
        autoClose: 3000
      });
      console.error(error);
      disconnect();
      setLoading(false);
      setStatus("idle");
    }
  };

  useEffect(() => {
    if (data.event === "SELECT_WALLET" && status === "idle") {
      setStatus("connecting");
    } else if (["CONNECT_SUCCESS", "MODAL_CLOSE"].includes(data.event) && status === "connecting" && isConnected) {
      setStatus("signing");
      setLoading(true);
      handleConnectWallet();
    } else if (data.event === "CONNECT_ERROR" && status === "connecting") {
      setStatus("idle");
      console.error("Error connecting wallet");
    }
  }, [login, data, signMessageAsync, status, isConnected]);

  const handleLinkWallet = async () => {
    localStorage.clear();
    if (!address) {
      open();
    } else {
      setLoading(true);
      setStatus("signing");
      handleConnectWallet();
    }
  };

  return (
    <ConnectWalletButton type="button" onClick={handleLinkWallet}>
      {loading ? <Spinner width={16} className="mx-4" /> : text}
    </ConnectWalletButton>
  );
};
