import { Button } from "./button";
import React from "react";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createAppKit } from '@reown/appkit/react'
import { base } from '@reown/appkit/networks'
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0
    }
  }
});

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "aea162f85e70b25b8dd88ce0e57fb571";

// 2. Create wagmiConfig
const metadata = {
  name: "Login Talent Protocol",
  description: "",
  url: location.origin,
  icons: ["https://talentprotocol.com/images/favicon.jpg"]
};



const networks = [base];

const wagmiAdapter = new WagmiAdapter({
  networks,
  projectId,
  enableCoinbase: true
})

createAppKit({
  adapters: [wagmiAdapter],
  networks,
  projectId,
  metadata,
  features: {
    email: false, // default to true
    socials: false,
    showWallets: true, // default to true
    walletFeatures: true ,// default to true
    analytics: true // Optional - defaults to your Cloud configuration
  },
  enableAnalytics: true,
  allowUnsupportedChain: false,
  themeVariables: {
    "--w3m-z-index": 2000
  },
  featuredWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa"
  ]
})


export const SignInWalletButton = ({ text, redirect_url }) => {
  return (
    <WagmiProvider config={wagmiAdapter.wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <Button text={text} redirect_url={redirect_url} requiredChain={base} />
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default (props, railsContext) => {
  return () => <SignInWalletButton {...props} railsContext={railsContext} />;
};
